exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-all-vacancies-jsx": () => import("./../../../src/pages/all-vacancies.jsx" /* webpackChunkName: "component---src-pages-all-vacancies-jsx" */),
  "component---src-pages-careers-page-jsx": () => import("./../../../src/pages/careers-page.jsx" /* webpackChunkName: "component---src-pages-careers-page-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-home-page-jsx": () => import("./../../../src/pages/home-page.jsx" /* webpackChunkName: "component---src-pages-home-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-desk-jsx": () => import("./../../../src/pages/investor-desk.jsx" /* webpackChunkName: "component---src-pages-investor-desk-jsx" */),
  "component---src-pages-media-room-jsx": () => import("./../../../src/pages/media-room.jsx" /* webpackChunkName: "component---src-pages-media-room-jsx" */),
  "component---src-pages-our-segments-jsx": () => import("./../../../src/pages/our-segments.jsx" /* webpackChunkName: "component---src-pages-our-segments-jsx" */),
  "component---src-pages-sunshine-jsx": () => import("./../../../src/pages/sunshine.jsx" /* webpackChunkName: "component---src-pages-sunshine-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */)
}

